import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
// theme
import theme from "../../theme";

const useStyles = makeStyles((th) => ({
  label: {
    fontSize: th.typography.body1.fontSize,
    transform: "translate(0, -13px) scale(1)",
    fontWeight: "bold",
  },
  descriptionLabel: {
    color: th.palette.dark,
    fontSize: 13,
    lineHeight: "20px",
  },
  inputContainer: {
    position: "relative",
    marginBottom: th.spacing(2),
  },
  input: {
    borderRadius: 0,
    border: ({ disabled }) => (!disabled ? th.borders[1] : th.borders[6]),
    paddingTop: th.spacing(1 / 2),
    paddingBottom: th.spacing(1 / 2),
    paddingLeft: th.spacing(1),
    width: "100%",
  },
}));

const MyTextField = ({
  InputProps,
  description,
  textFieldClassNames = "",
  labelClassNames = "",
  labelInputClassNames = "",
  inputClassNames = "",
  inputContainerClassNames = "",
  disabled = false,
  ...rest
}) => {
  const classes = useStyles({
    ...theme,
    extraMargin: !!description,
    disabled,
  });
  return (
    <Box className={`${classes.inputContainer} ${inputContainerClassNames}`}>
      {description && (
        <Box mb={1}>
          <Typography variant="subtitle1" className={`${classes.descriptionLabel} ${labelClassNames}`}>
            {description}
          </Typography>
        </Box>
      )}
      <TextValidator
        className={`${classes.field} ${textFieldClassNames}`}
        InputLabelProps={{
          shrink: true,
          className: `${classes.label} ${labelInputClassNames}`,
        }}
        InputProps={{
          className: `${classes.input} ${inputClassNames}`,
          variant: "outlined",
          ...InputProps,
        }}
        disabled={disabled}
        {...rest}
      />
    </Box>
  );
};

export default MyTextField;
